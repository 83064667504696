import { render, staticRenderFns } from "./FoodDetail.vue?vue&type=template&id=482876e6&scoped=true&"
import script from "./FoodDetail.vue?vue&type=script&lang=js&"
export * from "./FoodDetail.vue?vue&type=script&lang=js&"
import style0 from "./FoodDetail.vue?vue&type=style&index=0&id=482876e6&prod&scoped=true&lang=css&"
import style1 from "./FoodDetail.vue?vue&type=style&index=1&id=482876e6&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "482876e6",
  null
  
)

export default component.exports